.candidate-hero {
  .candidate-container {
    container-type: inline-size;
  }

  .h1 {
    font-size: 13cqw;
    font-weight: 700;
    line-height: 1em;
    position: relative;
    z-index: 1;

    .doodle {
      font-weight: 900;

      @include mq($from: desktop) {
        transform: translate3d(50%, -40%, 0);
      }
    }

    @include mq($from: mobile) {
      font-size: 7cqw;
    }

    .surround-candidate-hero {
      transform: translate3d(0, -20%, 0);
    }
  }

  .scroll-next {
    display: block;
    margin-top: 80px;

    @include mq($from: mobile) {
      display: none;
    }

    img {
      transform: rotate(90deg);
      width: 36px;
    }
  }

  .store-buttons {
    align-items: center;
    display: flex;
    justify-content: space-around;

    @include mq($from: mobile) {
      display: none;
    }
  }

  .img-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    .qr-code {
      background-color: #fff;
      border-radius: 10px;
      height: 130px;
      padding: 10px;
      width: 130px;
    }

    .store-light {
      height: 20px;
      width: auto;
    }

    .android {
      padding-bottom: 20px;
    }
  }

  .arm-button.small-screen {
    display: none;

    @include mq($from: mobile, $until: wide-xl) {
      display: block;
      margin-top: 80px;

      p {
        font-size: 3cqw;
      }

      .store {
        align-items: center;
        display: flex;
        gap: 20px;
        height: 160px;
        justify-content: center;
      }

      hr {
        background-color: #fff;
        height: 40%;
        margin-top: -10px;
        width: 2px;
      }
    }
  }

  .arm-button.large-screen {
    display: none;

    @include mq($from: wide-xl) {
      display: block;
      height: 80vh;
      position: fixed;
      right: 5%;
      top: -5%;
      width: auto;
      z-index: 10;
    }

    .inner {
      height: 100%;
      position: relative;
    }

    .store {
      bottom: 10%;
      display: flex;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 100%;
    }

    .store-img {
      width: 50%;
    }

    .pink-arm {
      bottom: 105%;
      position: absolute;
      right: 24%;
      width: 60%;
    }

    .buttons {
      background-color: #fe0d8c;
      border-radius: 40px;
      bottom: -15%;
      height: auto;
      left: -190px;
      padding-bottom: 20px;
      position: absolute;
      width: 250px;
      z-index: 11111;
    }

    .cta {
      font-size: 18px;
      font-weight: 700;
      margin-top: 20px;
    }
  }
}
